/* eslint-disable */
import { useState, useEffect } from "react";
import { Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import { AppstoreOutlined } from "@ant-design/icons";
import "@fontsource/montserrat";
import { history, store } from "redux/store";
import everestFleetLogo from "../../../assets/images/everestFleetLogo.png";
import { axiosInstance } from "services/axios";
import { connect } from "react-redux";

const localSidebarMenu = [
  {
    key: "/home/dashboard",
    label: "Dashboard",
    icon: <AppstoreOutlined style={{ fontSize: 24 }} />,
    permissions: ["view_lead"]
  },
  {
    key: "sub1",
    label: "Leads Management",
    icon: <AppstoreOutlined style={{ fontSize: 24 }} />,
    children: [
      {
        key: "/home/campaigns",
        label: "Campaigns",
        permissions: ["view_campaign"]
      },
      {
        key: "/home/leads",
        label: "Leads",
        permissions: ["view_lead"]
      },
      {
        key: "/home/import-lead-summary",
        label: "Import Lead Summary",
        permissions: ["view_importleadsummary"]
      },
      {
        key: "/home/onboard",
        label: "OnBoard",
        permissions: ["view_onboard"]
      },
      {
        key: "/home/review",
        label: "Review",
        permissions: ["view_callreview"]
      }
    ],
  },
  {
    key: "sub2",
    label: "Reports",
    icon: <AppstoreOutlined style={{ fontSize: 24 }} />,
    children: [
      {
        key: "/home/schedule-reports",
        label: "Schedule Reports",
        permissions: ["view_schedule"]
      },
      {
        key: "/home/telecaller-reports",
        label: "Tele Caller Reports",
        permissions: ["view_lead"]
      },
    ],
  },
  {
    key: "admin",
    label: "Admin",
    icon: <AppstoreOutlined style={{ fontSize: 24 }} />,
    children: [
      {
        key: "/home/roles",
        label: "Roles",
        permissions: ["view_group"]
      },
      {
        key: "/home/users",
        label: "Users",
        permissions: ["view_user"]
      },
      {
        key: "/home/cities",
        label: "Cities",
        permissions: ["view_city"]
      },
      {
        key: "/home/disposition",
        label: "Disposition",
        permissions: ["view_disposition"]
      },
      {
        key: "/home/disposition-mapping",
        label: "Disposition Mapping",
        permissions: ["view_dispositionmapping"]
      },
    ],
  }
];

const getParentKey = (pathname) => {
  for (const menu of localSidebarMenu) {
    if (menu.children) {
      for (const child of menu.children) {
        if (child.key === pathname) {
          return menu.key;
        }
      }
    }
  }
  return null;
};

const filterMenuByPermissions = (menu, permissions) => {
  return menu.reduce((acc, item) => {
    if (item.children) {
      const filteredChildren = filterMenuByPermissions(item.children, permissions);
      if (filteredChildren.length > 0) {
        acc.push({ ...item, children: filteredChildren });
      }
    } else if (item.permissions && item.permissions.some(permission => permissions.includes(permission))) {
      acc.push(item);
    }
    return acc;
  }, []);
};

const Sidebar = (props) => {
  const [selectedKeys, setSelectedKeys] = useState([props.location?.pathname]);
  const [menuListing, setMenuListing] = useState(localSidebarMenu);
  const [openKeys, setOpenKeys] = useState([getParentKey(props.location?.pathname)]);

  useEffect(() => {
    const permissions = props?.user?.permissions?.map(permission => permission.codename) || [];
    const filteredMenu = filterMenuByPermissions(localSidebarMenu, permissions);

    setMenuListing(filteredMenu);
  }, [props.user]);

  useEffect(() => {
    const parentKey = getParentKey(props.location?.pathname);
    setSelectedKeys([props.location?.pathname]);
    if (parentKey) {
      setOpenKeys([parentKey]);
    }
  }, [props.location?.pathname]);

  const handleClick = (e) => {
    setSelectedKeys([e.key]);
    history.push(e.key);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <>
      <div className="flex flex-col items-center py-2 border-b-[1px] header-logo">
        <Link to="/">
          <img src={everestFleetLogo} alt="app-logo" className="h-16 my-2" />
        </Link>
      </div>
      <Menu
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={handleClick}
        mode="inline"
        items={menuListing}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.userReducer?.userDetails
  }
}

export default connect(mapStateToProps)(withRouter(Sidebar));
